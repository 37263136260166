<template>
    <div class="page-content">
      <page-breadcrumb title="Native 1-1 Payment" class="mb-2" />
  
      <data-create-popup title="Create Payment Method"
        :schema="create_schema" :default-data="{ language: 'vi', type: 4}"
        :create-data-fn="createItem" @create="$refs.item_list.getList()"
      />
      <data-table-ssr id="item_list" ref="item_list"
        :columns="fields" :get-list-fn="getList"
        :delete-row-fn="deleteItem"
        :to-edit-fn="toEditPage"
      />
    </div>
  </template>
  
  <script>
  import service from '../service'
  const create_schema = [
    {
      cols: 12,
      fields: [
        { label: 'Market', field: 'language', input_type: 'language', validate: { required: true } },
        { label: 'Payment Method Type', field: 'type', input_type: 'select', options: [
            {text:"Banking", value: 2},
            {text:"Momo", value: 14}
        ], validate: { required: true }},
      ]
    }
  ];
  
  const fields = [
    { label: 'Market', field: 'language' },
  ];
  
  export default {
    data(){
      return{
        create_schema,
        fields,
        total: 0,
        editing_data: null,
      }
    },
    methods:{
      async createItem(data) {
        let new_item = await service.create({ ...data });
        return new_item;
      },
      async getList({ limit, page, query }) {
        let response_data = await service.getList({
          query: JSON.stringify({ ...query }),
          page, limit
        });
        let list = [], total = 0;
        if (response_data) {
          list = response_data.list;
          total = response_data.total;
          this.total = total;
        }
        return { list, total };
      },
      toEditPage(data) {
        this.$router.push({ name: 'native11-payment-detail', params: { id: data._id } });
      },
      async updateItem(data) {
        service.update(data);
        this.$refs.item_list.getList();
      },
      async deleteItem({ _id }) {
        await service.delete({ id: _id });
        this.$refs.item_list.getList();
      },
    }
  }
  </script>
  